<template>
  <b-row>
    <b-col sm="12" md="12" class="mt-1">
      <b-form @submit.prevent>
        <b-card>
          <b-card-body>
            <validation-observer
              #default="{ handleSubmit }"
              ref="refFormObserver"
            >
              <b-form
                @submit.prevent="handleSubmit(onSubmit)"
                @reset.prevent="resetForm"
              >
                <b-row>
                  <b-col sm="12" md="6" class="mt-1">
                    <label> {{ $t("fields.holdername") }}</label>

                    <inputvselect
                      name="holdername"
                      :label="
                        (reldata) =>
                          reldata.name
                      "
                      :valuex="appData.holdernameid"
                      keys="key"
                      @updatedata="(val) => updatedetails('holdernameid', val)"
                      :options="familymembers"
                      id="holdername"
                      validations="required"
                    ></inputvselect>
                  </b-col>

                  <b-col sm="12" md="6" class="mt-1">
                    <inputtext
                      name="loangivento"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.loangivento')
                      "
                      :label="$t('fields.loangivento')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.loangivento')
                      "
                      :valuex="appData.loangivento"
                      @updatedata="(val) => (appData.loangivento = val)"
                      validations="required"
                    ></inputtext>
                  </b-col>
                  <b-col sm="12" md="6" class="mt-1">
                    <inputtext
                      name="outstantamount"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.outstantamount')
                      "
                      :label="$t('fields.outstantamount')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.outstantamount')
                      "
                      :valuex="appData.outstantamount"
                      @updatedata="
                        (val) => managefloat('outstantamount', parseFloat(val))
                      "
                      validations="integer"
                    ></inputtext>
                  </b-col>
                  <b-col sm="12" md="6" class="mt-1">
                    <label> {{ $t("fields.returndate") }}</label>
                    <flat-pickr
                      v-model="appData.returndate"
                      :config="{ dateFormat: 'd-m-Y' }"
                      placeholder="DD-MM-YYYY"
                      class="form-control"
                    />
                  </b-col>
                  <b-col sm="12" md="6" class="mt-1">
                    <inputtext
                      name="remarks"
                      :tooltip="$t('fields.enter') + ' ' + $t('fields.remarks')"
                      :label="$t('fields.remarks')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.remarks')
                      "
                      :valuex="appData.remarks"
                      @updatedata="(val) => (appData.remarks = val)"
                      validations=""
                    ></inputtext>
                  </b-col>
                  <b-col sm="12" v-if="edittype == 0">
                    <h5 class="mt-2">{{ $t("fields.fileupload") }}</h5>
                    <b-row>
                      <b-col sm="12" md="6" class="mt-1" lg="6">
                        <validation-provider
                          #default="validationContext"
                          name="Upload File"
                          rules=""
                        >
                          <b-form-group
                             :label="$t('fields.uploadfile')"
                            label-for="fileinput"
                          >
                            <b-form-file
                              accept="image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx,.pages,.odt,.rtf"
                              id="fileinput"
                              name="Upload File"
                              :placeholder="$t('fields.uploadfile')"
                              drop-placeholder="Drop file here..."
                              @change="updateValue($event)"
                            />
                            <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col sm="12">
                    <div class="d-flex float-right">
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        class="mr-1"
                        variant="secondary"
                        @click="$router.go(-1)"
                      >
                        <feather-icon icon="ArrowLeftIcon" class="mr-25" />
                        <span class="align-middle"
                          >{{ $t("fields.back") }}
                        </span>
                      </b-button>
                      <b-button
                        v-if="edittype == 1"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="mr-1"
                        :to="{
                          name: GENAPP_APP_STORE_MODULE_NAME + '-view',
                          params: { id: iddata },
                        }"
                      >
                        <feather-icon icon="EyeIcon" class="mr-25" />
                        {{ $t("fields.view") }}
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary                            "
                        class="mr-1"
                        type="submit"
                      >
                        <feather-icon icon="Edit3Icon" class="mr-25" />
                        {{ $t("fields.save") }}
                      </b-button>
                    </div></b-col
                  >
                </b-row>
              </b-form>
              <!--/ form -->
            </validation-observer>
          </b-card-body>
        </b-card>
      </b-form>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BFormDatepicker,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormFile,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import inputvselect from "@/views/Component/Input/inputvselect.vue";
import inputselect from "@/views/Component/Input/inputselect.vue";
import inputtext from "@/views/Component/Input/inputtext.vue";
import inputtextarea from "@/views/Component/Input/inputtextarea.vue";
import { required, alphaNum } from "@validations";
import flatPickr from "vue-flatpickr-component";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import StoreModule from "./StoreModule";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
export default {
  components: {
    BCol,
    BCard,
    BRow,
    BForm,
    flatPickr,
    BCardBody,
    BFormGroup,
    inputselect,
    inputvselect,
    inputtextarea,
    inputtext,
    BFormCheckbox,
    BFormInput,
    BFormFile,
    BFormInvalidFeedback,
    BButton,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      alphaNum,
    };
  },
  setup(props, { emit }) {
    const GENAPP_APP_STORE_MODULE_NAME = "financialassets-receivables";
    const modulename = "Receivables";
    const modulenamesub = "receivables";
    // Register module
    if (!store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
      store.registerModule(GENAPP_APP_STORE_MODULE_NAME, StoreModule);
    const toast = useToast();
    onUnmounted(() => {
      if (store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
        store.unregisterModule(GENAPP_APP_STORE_MODULE_NAME);
    });

    let key = 0;
    let edittype = 0;
    let storetype = "addData";
    let iddata = router.currentRoute.params.id;
    if (iddata != undefined) {
      edittype = 1;
      storetype = "editData";
    }
    const blankAppData = {
      name: "",
      holdernameid: "",
      holdername: "",
      loangivento: "",
      returndate: "",
      remarks:"",
      outstantamount: 0,
      fileData: {
        name: "",
        file: "",
        size: "",
        type: "",
      },
    };
    const appData = ref(JSON.parse(JSON.stringify(blankAppData)));
    var tempcatch = 1;

    const onSubmit = () => {
      let temp = appData.value;
      let formData = new FormData();
      let appdatax = {
        customvalue: temp.relation != "Others" ? 0 : 1,
        customrelation: temp.customrelation || "",
        holdername: temp.holdername,
        returndate: temp.returndate,
        name: temp.loangivento,
        holdernameid: temp.holdernameid,
        remarks: temp.remarks,
        loangivento: temp.loangivento,
        outstantamount: temp.outstantamount,
      };
      let metadataappdatax = {
        customvalue: temp.relation != "Others" ? 0 : 1,
        customrelation: temp.customrelation || "",
        holdername: temp.holdername,
        name: temp.loangivento,
        returndate: temp.returndate? temp.returndate.split('-').reverse().join('-') : null,
        outstantamount: temp.outstantamount,
        holdernameid: temp.holdernameid,
      };
      if (edittype == 1) {
        var jx = {
          id: router.currentRoute.params.id || null,
          customvalue: metadataappdatax.customvalue,
          data: appdatax,
          name: temp.loangivento,
          metadata: metadataappdatax,
        };
      } else {
        formData.append("name", temp.loangivento);
        formData.append("customvalue", metadataappdatax.customvalue);
        formData.append("data", JSON.stringify(appdatax));
        formData.append("metadata", JSON.stringify(metadataappdatax));

        if (temp.fileData.file) {
          let file = temp.fileData.file;
          formData.append("file", file);
        }
      }
      if (tempcatch) {
        tempcatch = 0;
        store
          .dispatch(
            GENAPP_APP_STORE_MODULE_NAME + "/" + storetype,
            edittype == 0 ? formData : jx
          )
          .then((response) => {
            if (response.data.success) {
              toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  text:
                    modulename +
                    (edittype == 1 ? " editted" : " added") +
                    " successfully",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              router
                .push({
                  name: GENAPP_APP_STORE_MODULE_NAME,
                  params: {
                    id: response.data.id,
                  },
                })
                .catch(() => {});
            } else if (response.data.success == 0) {
              toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  variant: "error",
                },
              });
              router
                .push({
                  name: GENAPP_APP_STORE_MODULE_NAME,
                  params: {
                    id: response.data.id,
                  },
                })
                .catch(() => {});
            }
          })
          .catch((error) => {
            tempcatch = 1;

            if (error.response.status === 404) {
              appData.value = undefined;
              toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  text: "Couldnt find " + modulenamesub + " details",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            }
          });
      }
    };
    const familymembers = ref([]);
    store
      .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/fetchefamilymemberdetails")
      .then((response) => {
        familymembers.value = response.data.data;
      });

    // const state = ref([]);
    // store
    //   .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/fetchestate")
    //   .then((response) => {
    //     state.value = response.data.data;
    //   });
    if (iddata != undefined) {
      store
        .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/fetchData", {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          if (!response.data.data) {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Couldnt find " + modulenamesub + " details",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
            router
              .push({
                name: GENAPP_APP_STORE_MODULE_NAME,
              })
              .catch(() => {});
          }

          appData.value = response.data.data.data;
          appData.value.name = response.data.data.name;
          key = Math.random();
        })
        .catch((error) => {
          if (error.response.status === 404) {
            appData.value = undefined;
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Couldnt find " + modulenamesub + " details",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          } else if (error.response.status === 500) {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Input fields missing values",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          } else if (error.response.status === 422) {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Please refresh your page",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        });
    }
    const resetappData = () => {
      appData.value = JSON.parse(JSON.stringify(blankAppData));
    };
    const propertytype = [
      "Primary Residence ",
      "Investment",
      "Vacation Home",
      "Rented",
      "Others",
    ];
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetappData);
    return {
      GENAPP_APP_STORE_MODULE_NAME,
      edittype,
      iddata,
      appData,
      modulename,
      modulenamesub,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      propertytype,
      key,
      familymembers,
      tempcatch,

      // state
    };
  },
  mounted() {},
  methods: {
    updatedetails(tit, val) {

      if (tit == "holdernameid") {
        this.appData.holdernameid = val.id;
        this.appData.holdername = val.name;
      }
    },
    managefloat(name, val) {
      this.$set(this.appData, name, parseFloat(val));
    },
    updateValue(value) {
      if (value.target.files[0].size <= 2 * 1048576) {
        this.appData.fileData.file = value.target.files[0];
        this.appData.fileData.name = value.target.files[0].name;
        this.appData.fileData.size = value.target.files[0].size;
        this.appData.fileData.type = value.target.files[0].type;
      } else {
        this.$swal({
          icon: "error",
          title: "File limit Reached!",
          text: "File size must be maximum 2 mb .",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
